/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-toolbar.mat-primary {
    background: #000000;
    color: #fff;
  }

.img-fluid {
    max-width:430px;
    max-height:70px;
}

.docs-footer {
    background: #000000;
}

.snackbar-style{
  white-space: pre-wrap;
}

